export default {
  en: {
    form: {
      ui: {
        incomplete: 'Please fill out all fields.',
        submit: 'Save',
      },

      validation: {
        location: 'Location requires a valid city and state.'
      }
    },

    ui: {
      'privacyScore' : 'Privacy Score',
      'nScans' : 'Automatic Scans',
      'nRecordsFound' : 'Records Submitted',
      'nRecordsRemoved' : 'Records Removed',
      'agencyScore' : 'Agency Privacy Score',
      'automaticScans' : 'Automatic Scans',
      'nSeats' : 'Total Seats',
      'activeUsers' : 'Active Users',
      'totalRecords' : 'Total Records Found',
      'totalRemoved' : 'Total Records Removed',
    }
  }
};

// let group = 'ui';
// let key = 'privacyScore';

// _lang.en [group] [key]
