<template>
  <!-- <div class="Flex-column" style="width: 300px"> -->
  <div
    class="Flex-column"
    style="width: 100%; max-width: 100%"
  >
    <input
      v-model="password"
      type="password"
      name="password"
      class="input"
      :class="context.classes.input"
      @input="onInput"
    >

    <div class="password-strength-bars">
      <div
        v-for="i in 5"
        :key="i"
        class="password-strength-bar"
        :class="strength >= i ? `bg-strength-${strength}` : ''"
      />
    </div>

    <div
      v-if="password.length"
      class="password-strength-feedback"
    >
      Password Strength: <span :class="`fg-strength-${strength}`">{{ {
        1 : 'Very Poor',
        2 : 'Poor',
        3 : 'Okay',
        4 : 'Strong',
        5 : 'Very Strong'
      } [strength] }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import * as zxcvbn from 'zxcvbn';

let props = defineProps ({
  context: Object
});

let password = ref (props.context.value || '');
let strength = ref (0);

function onInput () {
  let check = zxcvbn (password.value);
  strength.value = password.value.length > 0 ? check.score + 1 : 0;
  props.context.node.input (password.value);
}
</script>

<style lang="scss">
  $password-strength-palette: (
    1: #bb2528,
    2: #ea4630,
    3: #f8b229,
    4: #146b3a,
    5: #165b33
  );

  .password-strength-bars {
    display: flex;
    margin-top: 1rem;
  }

  .password-strength-bar {
    background-color: rgba(var(--dark), var(--opacity-faint));
    height: 1rem;
    width: 20%;

    &:not(:last-child) {
      margin-right: .6rem;
    }
  }

  .password-strength-feedback {
    margin-top: 1.35rem;
    font-size: 14px;
  }

  @each $strength, $color in $password-strength-palette {
    .bg-strength-#{$strength} {
      background-color: $color;
    }

    .fg-strength-#{$strength} {
      color: $color;
    }
  }
</style>
