import { ref } from 'vue';
import { steps, plans, minTenants } from '~/src/onboard';
import { qs, empty } from '~/src/util';

let step = steps.profile;

// if (!empty (qs ('step'))) {
//   step = steps [qs ('step')];
// } else if (!empty (qs ('plan'))) {
//   step = steps.profile;
// }

const onboard = {
  namespaced: true,

  state: {
    step,

    plan: qs ('plan') || null,
    period: qs ('period') || '1m',

    profile: {
      email: qs ('email') || null,
      fullname: qs ('fullname') || null,
      birthYear: qs ('birthYear') || null,
      location: qs ('location') || null
    },

    agency: {
      name: null
    },

    // tenants: [],

    // profile: {},

    tenants: [],

    // tenants: [
    //   { fullname: 'Bill Gates', location: 'Seattle, WA', birthYear: 1980 },
    //   { fullname: 'John Doe', location: 'New York, NY', birthYear: 1980 },
    //   { fullname: 'Jack Smith', location: 'Seattle, WA', birthYear: 1980 },
    // ],

    // brokers: [],

    scans: {},

    records: [],

    referral: qs ('referral'),

    account: {
      password: null,
      mfa: false
    }
  },

  getters: {
    scan: (state) => () => {
      let merged = {
        progress: 0,

        riskFactors: {
          peopleSearches: 0,
          backgroundChecks: 0,
          dataBrokers: 0,
          darkWeb: 0
        }
      };

      let scanIds = Object.keys (state.scans);

      if (scanIds.length <= 0) {
        return merged;
      }

      for (let scanId of scanIds) {
        let scan = state.scans [scanId];

        if (!scan) {
          continue;
        }

        merged.progress += scan.progress;

        for (let riskFactor of Object.keys (merged.riskFactors)) {
          merged.riskFactors [riskFactor] += scan.riskFactors [riskFactor];
        }
      }

      merged.progress = Math.ceil (merged.progress / scanIds.length);

      for (let riskFactor of Object.keys (merged.riskFactors)) {
        merged.riskFactors [riskFactor] = Math.ceil (merged.riskFactors [riskFactor] / scanIds.length);
      }

      return merged;
    }
  },

  mutations: {
    step (state, value) {
      state.step = value;
    },

    plan (state, value) {
      state.plan = value;
    },

    period (state, value) {
      state.period = value;
    },

    profile (state, value) {
      state.profile = value;
    },

    account (state, value) {
      state.account = value;
    },

    // email (state, value) {
    //   state.profile.email = value;
    // },

    // fullname (state, value) {
    //   state.profile.fullname = value;
    // },

    // birthYear (state, value) {
    //   state.profile.birthYear = value;
    // },

    // location (state, value) {
    //   state.profile.location = value;
    // },

    tenants (state, value) {
      state.tenants = value;

      for (let i = 0; state.tenants.length < minTenants [state.plan] - 1; i++) {
        state.tenants.push ({});
      }
    },

    addTenant (state, profile) {
      profile = profile || {};
      state.tenants.push (profile);
    },

    removeTenant (state, index) {
      state.tenants.splice (index, 1);
    },

    scanning (state, scanIds) {
      for (let scanId of scanIds) {
        state.scans [scanId] = null;
      }
    },

    scan (state, scan) {
      if (
        !state.scans [scan.id] ||
        state.scans [scan.id].progress < 100
      ) {

        // Max sure progress strictly increases, websocket messages may be sent out of order.
        let progress = scan.progress;

        if (state.scans [scan.id]) {
          progress = Math.max (state.scans [scan.id].progress, progress);
        }

        scan.progress = progress;

        // // if there are multiple scans running, riskFactors should strictly increase
        // for (let riskFactor of Object.keys (scan.riskFactors)) {
        //   scan.riskFactors [riskFactor] = Math.max (
        //     scan.riskFactors [riskFactor],
        //     state.scan.riskFactors [riskFactor]
        //   );
        // }

        // // if multiple scans, update progress when the scan with the least progress
        // // has progressed

        // if (! (scan.id in state.progress)) {
        //   state.progress []
        // }

        // scan.progress = state.scan.progress + scan.progress / (state.tenants.length + 1);

        // state.scan = scan;

        state.scans [scan.id] = scan;
      }
    },

    record (state, record) {
      state.records.unshift (record);
    },

    agency (state, value) {
      for (let key of Object.keys (value)) {
        state.agency [key] = value [key];
      }
    },

    password (state, value) {
      state.account.password = value;
    },

    mfa (state, value) {
      state.account.mfa = value;
    },

    referral (state, value) {
      state.referral = value;
    }
  },

  actions: {
    forceFinish ({ state, rootState }) {
      for (let scanId of Object.keys (state.scans)) {
        state.scans [scanId].progress = 100;
      }

      for (let i = 0; i < rootState.brokers.length; i++) {
        rootState.brokers [i].loading = false;
      }
    },
  }
};

export { onboard };
