import { createStore } from 'vuex';
import { api } from '~/src/api';
import { onboard } from '~/src/store/onboard';
import { empty } from '~/src/util';
import { Mutex } from 'async-mutex';

// user -> [tenant] -> account -> profile

export default createStore ({
  state: {
    user: null,
    
    brokers: [],
    brokersLock: new Mutex (),

    focused: {
      account: null
    }
  },

  getters: {
    user: (state) => () => {
      return state.user;
    },

    userId: (state, getters) => () => {
      return getters.user ()?.id;
    },

    isLoggedIn: (state, getters) => () => {
      return !empty (getters.userId ());
    },

    accountId: (state, getters) => (master) => {
      return master ? getters.user ()?.account.id : state.focused.account?.id;
    },

    account: (state) => (master) => {
      return master ? getters.user ()?.account : state.focused.account;
    },

    tenants: (state, getters) => () => {
      return getters.user ()?.account.tenants;
    },

    fullname: (state, getters) => () => {
      return getters.user ()?.account.profile.name.display;
    },

    plan: (state, getters) => () => {
      return getters.user ()?.account.plan;
    },

    focused: (state) => () => {
      return state.focused.account;
    },

    isProvisional: (state, getters) => () => {
      return getters.isLoggedIn () && empty (getters.user ().enrollment);
    },

    isOwner: (state, getters) => () => {
      return getters.accountId (false) === getters.accountId (true);
    },

    brokers: (state) => () => {
      return state.brokers;
    }
  },

  mutations: {
    user (state, user) {
      state.user = user;
      state.focused.account = user.account;
    },

    account (state, account) {
      if (state.user.account.id === account.id) {
        state.user.account = account;
      }

      state.focused.account = account;
    },

    profile (state, profile) {
      if (state.user.account.profile.id === profile.id) {
        state.user.account.profile = profile;
      }

      state.focused.account.profile = profile;
    },

    brokers (state, brokers) {
      for (let broker of brokers) {
        broker.loading = true;
      }

      state.brokers = brokers;
    },
  },

  actions: {
    async sync (ctx) {
      ctx.commit ('user', (await api.whoami ()).data?.body);
    },

    async switchAccount (ctx, accountId) {
      ctx.commit ('account', (await api.getAccount (accountId)).data?.body);
    },

    async loadBrokers (ctx) {
      let release = await ctx.state.brokersLock.acquire ();
      
      if (ctx.getters.brokers ().length > 0) {
        release ();
        return;
      }

      ctx.commit ('brokers', ((await api.getBrokers ()).data?.body));
      release ();
    }
  },

  modules: {
    onboard
  }
});

// await store.dispatch ('sync')

// if (to.matched.some (record => record.meta.auth)) {
//   if (!store.isLoggedIn ()) {
//     // next ({
//     //   path: '/login',
//     //   query: { redirect: to.fullPath }
//     // })
//   }
// } else if (to.matched.some (record => record.meta.guest)) {
//   if (store.isLoggedIn ()) {
//     // next ({
//     //   path: '/login',
//     //   query: { redirect: to.fullPath }
//     // })
//   }
// }

//
