<template>
  <select 
    v-model="birthYear"
    name="birthYear"
    :class="context.classes.input"
    @change="handleInput"
  >
    <option
      v-for="offset in 100"
      :key="offset"
    >
      {{ (new Date ().getFullYear () - 17 - offset) }}
    </option>
  </select>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps ({
  context: Object
});

props.context.label = 'Year Born';

if (typeof props.context.help === 'undefined') {
  props.context.help  = 'We use your birth year reduce the number of false-positives we find when removing your information from the internet.';
}

let birthYear = ref (props.context.value || '');

function handleInput () {
   props.context.node.input (birthYear.value);
}
</script>