<template>
  <input
    v-model="code"
    type="text"
    :class="context.classes.input"
    @input="handleInput"
  >
</template>

<script setup>
import { ref } from 'vue';
import { api } from '~/src/api';
import { delay } from '~/src/util';

let props = defineProps ({
  context: Object
});

props.context.label = 'Referral code';

let code = ref (props.context.value || '');

function handleInput () {
  delay ('validation', async () => {
    try {
      await api.post ('/validate/referral', {
        referral: code.value
      });

      props.context.node.input (code.value);
      props.context.node.setErrors ([]);
    } catch (e) {
      props.context.node.setErrors (null, 'Invalid referral code.');
    }
  }, 750);
}
</script>