import config from './config.js';
import * as axios from 'axios';
import { base } from './debug.js';
import { getUser, getEnv, getHost, qs } from './util';

const debug = base.extend ('api');

debug.log = console.log.bind (console);

export function create (name, settings) {
  const format = 'https://[user][api]-[env].[realm]';
  //const format = 'https://[user][api]-[env]-v3.[realm]';

  const user = getUser ();

  let baseURL = format
    .replace ('[user]', user ? user + '.' : '')
    .replace ('[api]', name)
    .replace ('[env]', getEnv ())
    .replace ('[realm]', getHost ())
    .replace ('-prd', '');

  const instance = axios.create ({
    baseURL,
    // timeout: 3000,
    timeout: 0,

    ...(settings || {})
  });

  instance.interceptors.request.use (
    request => {
      debug ('%s %s',
        request.method.toUpperCase (),
        request.url
      );

      return request;
    }
  );

  instance.interceptors.response.use (
    response => {
      debug ('%s %s (%s %s)',
        response.status,
        response.statusText,
        response.config.method.toUpperCase (),
        response.config.url
      );

      return response;
    },

    error => {
      if (error.config) {
        debug ('%s: %s (%s: %s)',
          error.name,
          error.message,
          error.config.method.toUpperCase (),
          error.config.url
        );
      } else {
        debug ('%s: %s',
          error.name,
          error.message
        );
      }

      throw error;
    }
  );

  instance.interceptors.response.use (
    response => {
      if (response.data.redirect) {
        window.location.replace (response.data.redirect);
      }

      return response;
    }
  );

  return instance;
}

const api = create ('api', {
  withCredentials: true
});

api.whoami = () => {
  return api.get ('/me');
};

api.getAccount = (id) => {
  return api.get (`/account/${id}`);
};

api.getProfile = (id) => {
  return api.get (`/profile/${id}`);
};

api.getBreach = (id) => {
  return api.get (`/breach/${id}`);
};

api.getBrokers = (id) => {
  return api.get ('/brokers?enabled=true');
};

const auth = {};

auth.login = ({ email, password, token }) => {
  return api.post ('/login' + window.location.search, {
    email,
    password,
    token
  });
};

auth.loginWithCode = ({ code }) => {
  return api.post ('/login' + window.location.search, {
    code
  });
};

auth.register = ({ plan, period, profile, tenants, scans, agency, account, affiliate, couponId, referral }) => {
  return api.post ('/register', {
    plan,
    period,
    profile,
    tenants,
    scans,
    agency,
    account,
    affiliate,
    couponId,
    referral
  });
};

auth.forgotPassword = ({ email }) => {
  return api.post ('/forgot-password', {
    email
  });
};

auth.resetPassword = ({ password }) => {
   return api.post ('/reset-password', {
     token: qs ('token'),
     password
   });
 };

auth.registerTenant = ({ fullname, birthYear, location, password }) => {
   return api.put ('/tenant', {
      token: qs ('token'),
      fullname,
      birthYear,
      location,
      password
   });
};

export {
  api,
  auth
};
