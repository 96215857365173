<template>
  <input
    v-model="fullname"
    type="text"
    placeholder="e.x. John Taylor Doe"
    :class="context.classes.input"
    @input="handleInput"
  >
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps ({
  context: Object
});

props.context.label = 'Full Name';
// props.context.help  = 'Your name is the primary piece of information we match when removing your information from the internet.';

let fullname = ref (props.context.value || '');

function handleInput () {
   props.context.node.input (fullname.value);
}
</script>