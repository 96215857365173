const steps = {
  profile: 1,
  select: 2,
  payment: 3,
  summary: 4
};

const periods = {
  monthly: '1m',
  yearly: '1y'
};

const plans = {
  individual: 'Individual',
  family: 'Family',
  agency: 'Agency'
};

const prices = {
  [plans.individual]: {'1m': 12.99, '1y': 99.99},
  [plans.family]:     {'1m': 10.99, '1y': 89.99},
  [plans.agency]:     {'1m': 8.99,  '1y': 79.99}
};

const minTenants = {
  [plans.individual]: 0,
  [plans.family]: 2,
  [plans.agency]: 10
};

const maxTenants = {
  [plans.individual]: 0,
  [plans.family]: 10,
  [plans.agency]: Math.INF
};

export {
  steps,
  periods,
  plans,
  prices,
  minTenants,
  maxTenants
};
