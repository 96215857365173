<template>
  <input
    v-model="location"
    type="text"
    placeholder="e.x. Seattle, WA"
    :class="context.classes.input"
    @input="handleInput"
  >
</template>

<script setup>
import { ref } from 'vue';
import { api } from '~/src/api';
import { delay } from '~/src/util';

let props = defineProps ({
  context: Object
});

props.context.label = 'Location';

if (typeof props.context.help === 'undefined') {
  props.context.help  = 'We use your location primarily to positively identify records subject to our information removal process.';
}

let location = ref (props.context.value || '');

function handleInput () {
  delay ('validation', async () => {
    try {
      await api.post ('/validate/address', {
        address: location.value
      });

      props.context.node.input (location.value);
      props.context.node.setErrors ([]);
    } catch (e) {
      props.context.node.setErrors (null, { location: e.response.data.error });
    }
  }, 750);
}
</script>