import { api } from '~/src/api';

export async function location (node) {
  try {
    await api.post ('/validate/address', {
      address: node.value
    });

    return true;
  } catch (e) {
    return false;
  }

  return true;
};