import { createRouter, createWebHistory } from "vue-router";
import store from "~/src/store/index.js";
import { api } from "~/src/api.js";
import config from "~/src/config.js";
import { scrollBehavior } from "~/src/util.js";
import { plans } from "~/src/onboard.js";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Login" },
  },

  {
    path: "/landing",
    name: "Landing",
    redirect: (to) => {
      window.location.replace (config.fe);
      return {};
    },
  },

  // {
  //   path: "/checkout",
  //   name: "Checkout",
  //   redirect: (to) => {
  //     window.location.replace(
  //       `${api.defaults.baseURL}/account/${store.getters.accountId()}/checkout`
  //     );
  //   },
  // },

  {
    path: "/checkout/period/:period",
    name: "Checkout",
    redirect: (to) => {
      window.location.replace (
        `${
          api.defaults.baseURL
        }/account/${store.getters.accountId ()}/checkout?period=${
          to.params.period
        }`
      );
    },
  },

  // {
  //   name: 'Home',
  //   redirect: { name: 'Login' }

  //   // redirect: to => {
  //   //   window.location.replace (config.fe);
  //   //   return {};
  //   // }
  // },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import ("~/src/pages/Dashboard/Home.vue"),
    meta: { auth: true },
  },

  {
    path: "/dashboard/account",
    name: "Account",
    component: () => import ("~/src/pages/Dashboard/Account.vue"),
    meta: { auth: true },
  },

  {
    path: "/dashboard/agency",
    name: "Agency",
    component: () => import ("~/src/pages/Dashboard/Tenants.vue"),
    meta: { auth: true },
  },

  {
    path: "/login",
    name: "Login",
    component: () => import ("~/src/pages/Auth/Login.vue"),
    meta: { guest: true },
  },

  {
    path: "/login/mfa",
    name: "MFA",
    component: () => import ("~/src/pages/Auth/MFA.vue"),
    meta: { guest: true },
  },

  {
    path: "/login/error",
    component: () => import ("~/src/pages/Auth/LoginError.vue"),
    meta: { guest: true },
  },

  {
    path: "/forgot-password",
    component: () => import ("~/src/pages/Auth/ForgotPassword.vue"),
    name: "ForgotPassword",
    meta: { guest: true },
  },

  {
    path: "/reset-password",
    component: () => import ("~/src/pages/Auth/ResetPassword.vue"),
    name: "ResetPassword",
    meta: { guest: true },
  },

  {
    path: "/signup",
    name: "Signup",
    component: () => import ("~/src/pages/Signup.vue"),
    meta: { guest: true },
  },

  {
    path: "/signup/invitation",
    name: "SignupInvitation",
    component: () => import ("~/src/pages/Signup/Invitation.vue"),
    meta: { guest: true },
  },

  {
    path: "/signup/checkout",
    name: "SignupCheckout",
    component: () => import ("~/src/pages/Checkout.vue"),
  },

  {
    path: "/logout",
    name: "Logout",

    redirect: (to) => {
      window.location.replace (`${api.defaults.baseURL}/logout`);
      return {};
    },
  },

  {
    path: "/logout/success",
    component: () => import ("~/src/pages/Auth/LogoutSuccess.vue"),
  },

  {
    path: "/checkout/success",
    component: () => import ("~/src/pages/Checkout/Success.vue"),
  },

  {
    path: "/checkout/canceled",
    component: () => import ("~/src/pages/Checkout/Canceled.vue"),
  },
];

const router = createRouter ({
  history: createWebHistory (),
  routes,
  scrollBehavior,
});

router.beforeEach (async (to, from, next) => {
  if (!store.getters.isLoggedIn ()) {
    await store.dispatch ("sync");
  }

  if (to.matched.some ((record) => record.meta.auth)) {
    if (!store.getters.isLoggedIn ()) {
      next ({
        name: "Login",
        query: { redirect: to.fullPath },
      });

      return;
    }
  } else if (to.matched.some ((record) => record.meta.guest)) {
    if (store.getters.isLoggedIn ()) {
      next ({
        name: "Dashboard",
      });

      return;
    }
  }

  next ();
});

export default router;
