import '@stripe/stripe-js';

import { createApp } from 'vue';
import App from '~/src/App.vue';
import router from '~/src/router';
import store from '~/src/store';
import { plugin, defaultConfig, createInput } from '@formkit/vue';
import Switch from '~/src/components/Form/Switch';
import Password from '~/src/components/Form/Password';
import Name from '~/src/components/Form/Name';
import Location from '~/src/components/Form/Location';
import BirthYear from '~/src/components/Form/BirthYear';
import EmailAddr from '~/src/components/Form/Email';
import Referral from '~/src/components/Form/Referral';
import LocationInput from '~/src/components/Form/Location';
import { location } from '~/src/validation';
import lang from '~/src/lang';
import { createMetaManager } from 'vue-meta';

import '~/src/styles/main.scss';

createApp (App)
  .use (store)
  .use (router)
  .use (createMetaManager ())
  .use (plugin, defaultConfig ({
    inputs: {
      switch: createInput (Switch, {
        props: ['states', 'size']
      }),

      passwordStrength: createInput (Password, {
        props: []
      }),

      name: createInput (Name),

      birthYear: createInput (BirthYear),
      emailAddr: createInput (EmailAddr),
      location: createInput (LocationInput),
      referral: createInput (Referral)
    },

    rules: {
      location
    },

    messages: {
      en: lang.en.form
    }
  }))
  .mount ('#app');
