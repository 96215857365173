export * from '~/src/polyfill';
import _lang from '~/src/lang';

//(_lang.en [group] [key] != ''0594
export function lang (group, key) {
  if (key in _lang.en [group]) {
    return _lang.en [group] [key];
  } else {
    return false;
  }
}

export function getCssVar (colorName, element) {
  return getComputedStyle (element || document.body).getPropertyValue (colorName);
}

export function rgba (varname, opacity) {
  return `rgba(var(${varname.toLowerCase ()}), ${opacity || 1})`;
}

export function deg2rad (deg) {
  return deg * Math.PI / 180;
}

export function empty (s) {
  return typeof s === 'undefined' || !s;
}

// let pos = {
//   x: 0,
//   y: 0
// };

// export function setScroll (coords) {
//   coords = coords || {};

//   if (! ('x' in coords)) {
//     coords.x = window.scrollX;
//   }

//   if (! ('y' in coords)) {
//     coords.y = window.scrollY;
//   }

//   pos.x = coords.x;
//   pos.y = coords.y;
// }

// export function scrollBehavior (to, from, savedPosition) {
//   let scrollTo = {};

//   if (savedPosition) {
//     scrollTo = savedPosition;
//   } else {
//     scrollTo.x = pos.x;
//     scrollTo.y = pos.y;
//   }

//   setScroll ({
//     x: 0,
//     y: 0
//   });

//   return scrollTo;
// }

export function scrollBehavior (to, from, savedPosition) {
  return { top: 0 };
}

// -- -- //

export function ucfirst (s) {
  return s.substring (0, 1).toUpperCase () + s.substring (1);
}

export function round (s, p) {
  return Math.floor (s * Math.pow (10, p)) / Math.pow (10, p);
}

// export function findParent (em, tag) {
//   tag = tag.toUpperCase ();

//   while (em && em.tagName !== tag) {
//     em = em.parentNode;
//   }

//   return em;
// }

function qs (param) {
  return new URLSearchParams (window.location.search).get (param);
}

function getEnv () {
  return {
    dev: 'dev',
    tst: 'tst',
    stg: 'stg',
    prd: 'prd',
    demo: 'demo'
  }[process.env.NODE_ENV] || 'prd';
}

function getHost () {
  return window.location.hostname.split ('.').slice (-2).join ('.');
}

function getRealm () {
  return window.location.hostname.split ('.').slice (-2)[0];
}


function getUser () {
  let pts = window.location.hostname.split ('.');

  if (pts.length >= 4) {
    return pts [0];
  }

  return null;
}

function getEnvSubdomain () {
  let env = getEnv ();

  if (env === 'prd' || env === 'demo') {
    return '';
  } else {
    return `-${env}`;
  }
}

export {
  qs,
  getUser,
  getEnv,
  getHost,
  getRealm,
  getEnvSubdomain
};

// -- -- //

function fadein (em) {
  em.classList.remove ('fadeout');
  em.classList.add ('fadein');
}

function fadeout (em) {
  em.classList.remove ('fadein');
  em.classList.add ('fadeout');
}

export {
  fadein,
  fadeout
};

// -- -- //

// delay enforcing strictly 1 timeout. prevents timeouts from queueing up if
// the issuance rate is less than the timeout delay.

// e.x. delay ('request', () => { fetch (...) }, 2000);

function delay (id, ondelay, ms, onabort) {
  delay.timeouts = delay.timeouts || {};

  if (id in delay.timeouts) {
    clearTimeout (delay.timeouts[id]);
    typeof onabort === 'function' ? onabort () : (() => {}) ();
  }

  delay.timeouts[id] = setTimeout (ondelay, ms);
}

function getKeyByValue (obj, value) {
  return Object.keys (obj).find (k => obj[k] === value);
}

function uniq (A, key) {
  let values = [];

  for (let row of A) {
    if (key in row) {
      if (!values.includes (row [key])) {
        values.push (row [key]);
      }
    }
  }

  return values;
}

function img64 (base64) {
  return `data:image/jpeg;base64,${base64}`;
}

function plural (s, n) {
  if (n == 1) {
    return s;
  } else {
    return `${s}s`;
  }
}

export {
  delay,
  getKeyByValue,
  uniq,
  img64,
  plural
};

// -- -- //
