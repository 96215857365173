<template>
  <input
    :id="id"
    class="switch"
    type="checkbox"
    :name="id"
    :checked="state == 1"
    @change="onChange"
  >
  <label
    class="switch-label"
    :for="id"
    :style="`--font-size: ${size}px`"
  />
</template>

<script setup>
import { ref } from 'vue';
import { getKeyByValue } from '~/src/util';

let props = defineProps ({
  context: Object
});

let id = `${Math.random ()}`;
let states = props.context.states || [false, true];

// 1m -> 0, 1y -> 1, lookup key by value
let state = ref (getKeyByValue (states, props.context.value || false));
let size = ref (props.context.size || 12);

function onChange (e) {
  state.value = e.target.checked;
  props.context.node.input (states[e.target.checked ? 1 : 0]);
}
</script>

<style lang="scss" scoped>
  .switch[type=checkbox] {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .switch-label {
    font-size: var(--font-size);
    width: 5em;
    cursor: pointer;
    height: 2.5em;
    display: block;
    position: relative;
    background: rgba(var(--dark), var(--opacity-muted));
    text-indent: -9999px;
    border-radius: 10rem;
    margin-bottom: 6px;
    // margin-left: auto;

    @include media("<desktop") {
      margin: 0;
    }
  }

  .switch-label:after {
    top: .3em;
    left: .3em;
    width: 1.9em;
    height: 1.9em;
    content: '';
    position: absolute;
    background: rgba(var(--light), 1);
    transition: 0.3s;
    border-radius: 9rem;
  }

  .switch:checked + .switch-label {
    background: rgba(var(--primary), 1);
  }

  .switch:checked + .switch-label:after {
    left: calc(100% - .3rem);
    transform: translateX(-100%);
  }
</style>
